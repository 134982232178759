import * as auth from "./auth";
import * as banks from "./banks";
import * as systemUsers from "./systemUsers";
import * as limits from "./limits";
import * as moneyCreator from "./moneyCreator";
import * as keyRecoveryRequests from "./keyRecoveryRequests";
import { setAuthToken, clearAuthToken, getAuthToken } from "./instance";

export const api = {
  setAuthToken,
  clearAuthToken,
  getAuthToken,
  auth,
  banks,
  systemUsers,
  limits,
  moneyCreator,
  keyRecoveryRequests,
};
