import { createReducer } from "@/services/helpers/reducer";
import cookies from "js-cookie";
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  MONEY_CREATOR_LOGIN_REQUEST,
  MONEY_CREATOR_LOGIN_SUCCESS,
  MONEY_CREATOR_LOGIN_FAILURE,
} from "./actions";

const initialState = {
  isLoading: false,
  role: "admin", // Remove this if money-creators will be returned
  username: cookies.get("username"),
  privateKey: "",
};

const reducerMap = {
  [ADMIN_LOGIN_REQUEST]: draft => {
    draft.isLoading = true;
  },
  [ADMIN_LOGIN_SUCCESS]: (draft, username) => {
    draft.isLoading = false;
    draft.role = "admin";
    draft.username = username;
  },
  [ADMIN_LOGIN_FAILURE]: draft => {
    draft.isLoading = false;
  },
  [MONEY_CREATOR_LOGIN_REQUEST]: draft => {
    draft.isLoading = true;
  },
  [MONEY_CREATOR_LOGIN_SUCCESS]: (draft, { accountId, privateKey }) => {
    draft.isLoading = false;
    draft.role = "money-creator";
    draft.username = accountId;
    draft.privateKey = privateKey;
  },
  [MONEY_CREATOR_LOGIN_FAILURE]: draft => {
    draft.isLoading = false;
  },
};

export const reducer = createReducer(initialState, reducerMap);
