import { createReducer } from "@/services/helpers/reducer";
import { CHANGE_QUERY_PARAMS } from "./rootActions";

const initialState = {};

const reducerMap = {
  [CHANGE_QUERY_PARAMS]: (draft, payload) => {
    return payload;
  },
};

export const queryParamsReducer = createReducer(initialState, reducerMap);
