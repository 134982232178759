import React, { Suspense, lazy } from "react";
import { LoadingBackground } from "@/components/LoadingBackground";

// eslint-disable-next-line
const AppConnected = lazy(() => import("./AppConnected"));

export function App() {
  return (
    <Suspense fallback={<LoadingBackground />}>
      <AppConnected />
    </Suspense>
  );
}
