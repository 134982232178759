import { axiosInstance as axios } from "../instance";

async function getBanksList() {
  const { data } = await axios.get("/tvs-v1/api/banks/admin-ui-banks");

  return data;
}

async function createBank(payload) {
  const { data } = await axios.post("/tvs-v1/api/banks", payload);

  return data;
}

async function updateBank(payload) {
  const { data } = await axios.put("/tvs-v1/api/banks", payload);

  return data;
}

async function updatePgToCoreCredentials(payload) {
  const { data } = await axios.put("/tvs-v1/api/banks/password", payload);

  return data;
}

export { getBanksList, createBank, updateBank, updatePgToCoreCredentials };
