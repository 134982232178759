/* eslint-disable react/prop-types */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import queryString from "query-string";
import { queryParamsSelector } from "@/services/helpers/selectors";

const mapStateToProps = state => ({
  queryParams: queryParamsSelector(state),
});

/**
 * High order component that adds 'queryParams' and 'onChangeQueryParam' prop from the redux store.
 * @param {ReactNode} Component Accepts react component.
 *
 * @returns {ReactNode} Returns a connected component with "queryParams" and "onChangeQueryParam" props.
 */
export function withQueryParams(Component) {
  function WrapperComponent({ history, location, queryParams, ...otherProps }) {
    /**
     * Function that changes search query params
     * @param {Object} changeQueryParams
     * @returns {undefined}
     */
    const onChangeQueryParams = newQueryParams => {
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify({
          ...queryParams,
          ...newQueryParams,
        }),
      });
    };

    return (
      <Component
        queryParams={queryParams}
        onChangeQueryParams={onChangeQueryParams}
        history={history}
        location={location}
        {...otherProps}
      />
    );
  }

  return compose(
    connect(mapStateToProps),
    withRouter,
  )(WrapperComponent);
}
