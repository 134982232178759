/* eslint-disable no-underscore-dangle */
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import { createRootReducer } from "./rootReducer";
import { rootSaga } from "./rootSaga";
import { sagaMiddleware } from "./sagaMiddleware";
import { history } from "./history";
import { createReducerInjector } from "./reducerInjector";
import { createSagaInjector } from "./sagaInjector";

/**
 * Creates a redux store with possibility to dynamically load reducers and sagas after creation.
 * @param {Object} initialState Some initial state.
 *
 * @returns {Object} Returns a store.
 *
 * To read more about this technique:
 * @link https://stackoverflow.com/questions/32968016/how-to-dynamically-load-reducers-for-code-splitting-in-a-redux-application
 * @link https://github.com/erikras/ducks-modular-redux
 */
function configureStore(initialState = {}) {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

  const store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
  );

  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {};
  store.injectedSagas = {};

  if (module.hot) {
    module.hot.accept("./rootReducer", () => {
      store.replaceReducer(createRootReducer(store.injectedReducers));
    });
  }

  return store;
}

const store = configureStore();

// Creating injectors for redux ducks
const reducerInjector = createReducerInjector(store);
const sagaInjector = createSagaInjector(store);
store.runSaga(rootSaga);

export { store, reducerInjector, sagaInjector, history };
