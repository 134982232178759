import { DEFAULT_PAGE_SIZE } from "@/services/constants";
import { axiosInstance as axios } from "../instance";

export async function fetchList({
  page = 0,
  size = DEFAULT_PAGE_SIZE,
  sort = "receivedDate,desc",
  filter,
} = {}) {
  const { data } = await axios.get("/tvs-v1/api/account-recovery", {
    params: { page, size, sort, filter },
  });

  return data;
}

export async function fetchApplication(trxHash) {
  const { data } = await axios.get(`/tvs-v1/api/account-recovery/application/${trxHash}`);

  return data;
}

export async function approve(trxHash) {
  const { data } = await axios.put(`/tvs-v1/api/account-recovery/approve/${trxHash}`);

  return data;
}

export async function reject(trxHash, reason = "") {
  const { data } = await axios.put(`/tvs-v1/api/account-recovery/reject/${trxHash}`, { comment: reason });

  return data;
}
