import React from "react";
import { node, bool, string } from "prop-types";
import cn from "classnames";
import styles from "./styles.module.scss";

export function Container({ children, noShadow, className }) {
  return (
    <div
      className={cn({
        [styles.container]: true,
        [styles.shadow]: !noShadow,
        [className]: true,
      })}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: node,
  noShadow: bool,
  className: string,
};

Container.defaultProps = {
  children: null,
  noShadow: false,
  className: "",
};
