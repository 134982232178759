import { compose } from "redux";
import { connect } from "react-redux";
import { withReducer, withSaga } from "@/services/helpers/components";
import { Login } from "@/components/Login";
import { adminLoginRequest, moneyCreatorLoginRequest } from "./actions";
import { reducer } from "./reducer";
import { mainSaga } from "./sagas";

const name = "login";

const mapStateToProps = state => ({
  isLoading: state[name].isLoading,
});

const mapDispatchToProps = {
  onAdminLogin: adminLoginRequest,
  onMoneyCreatorLogin: moneyCreatorLoginRequest,
};

export default compose(
  withReducer(name, reducer),
  withSaga(name, mainSaga),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Login);
