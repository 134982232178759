import { sagaInjector } from "@/store";

/**
 * High order component that injects your saga.
 * @param {string} name general name of component and saga.
 * @param {function} saga a saga that should be injected.
 *
 * @returns {function} Returns a function that accepts your component.
 */
export function withSaga(name, saga) {
  sagaInjector({ name, saga });

  return component => component;
}
