import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./Root";

if (process.env.REACT_APP_ENABLE_MIMIC === "true") {
  import("mimic");
}

export const rootNode = document.getElementById("root");

ReactDOM.render(<Root />, rootNode);
