import { createRootReducer } from "./rootReducer";

const errorChecker = (name, reducer) => {
  if (!name) throw new Error("You haven't provided name of component");
  if (!reducer) throw new Error("You haven't provided a reducer");
  if (typeof name !== "string")
    throw new Error("A name of component is not a string");
  if (typeof reducer !== "function")
    throw new Error("Reducer should be a function");
};

/**
 * A factory that produce function to dynamically load given reducer to the store.
 * @param {Object} store A store.
 *
 * @returns {function} Returns a function that accepts your injecting reducer.
 *
 * To read more about this technique:
 * @link https://stackoverflow.com/questions/32968016/how-to-dynamically-load-reducers-for-code-splitting-in-a-redux-application
 * @link https://github.com/erikras/ducks-modular-redux
 */
const createReducerInjector = store => ({ name, reducer }) => {
  if (process.env.NODE_ENV === "development") {
    errorChecker(name, reducer);
  }

  // TODO: add hot reloading for reducers
  if (
    !(
      Reflect.has(store.injectedReducers, name) &&
      store.injectedReducers[name] === reducer
    )
  ) {
    store.injectedReducers[name] = reducer; // eslint-disable-line no-param-reassign
    store.replaceReducer(createRootReducer(store.injectedReducers));
  }
};

export { createReducerInjector };
