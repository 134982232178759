import produce from "immer";

/**
 * Reducer creator helper with immutability provided by Immer.js
 * @param {object} initialState an object describing initial state of current combined reducer.
 * @param {object} reducerMap an object where a key is an action type and a value is function, accepting draft of a current state (that can be directly, but immutably changed), action payload and real current state
 *
 * @returns {function} Returns an reducer that is ready to use in your redux app.
 *
 * To read more about Immer.js:
 * @link https://github.com/mweststrate/immer
 */
export const createReducer = (initialState, reducerMap) =>
  produce(
    (draft, action, rootState) =>
      reducerMap[action.type] &&
      reducerMap[action.type](draft, action.payload, rootState),
    initialState,
  );
