import { axiosInstance as axios } from "../instance";

async function registerMoneyCreator(payload) {
  const { data } = await axios.post("/tvs-v1/api/money-creator", payload);

  return data;
}

async function addAsset(payload) {
  const { data } = await axios.post(
    "/tvs-v1/api/money-creator/add-assets",
    payload,
  );

  return data;
}

async function transferAsset(payload) {
  const { data } = await axios.post(
    "/tvs-v1/api/money-creator/transfer-assets",
    payload,
  );

  return data;
}

async function getAccountAssets(accountId) {
  const { data } = await axios.get(`/tvs-v1/api/assets?accountId=${accountId}`);

  return data;
}

export { registerMoneyCreator, addAsset, getAccountAssets, transferAsset };
