/* eslint-disable no-param-reassign */
import axios from "axios";
import cookies from "js-cookie";

const storedToken = cookies.get("token");

const axiosInstance = axios.create({
  baseURL: process.env.BACKEND_BASE_URL,
  timeout: 30000,
  responseType: "json",
  headers: {
    Authorization: storedToken && `Bearer ${storedToken}`,
  },
});

axiosInstance.interceptors.response.use(
  response => {
    if (response.data && response.data.errorCode) {
      const error = new Error("Unhandled error occurred");
      if (response.data.errorPhrase) error.title = response.data.errorPhrase;
      if (response.data.message) error.message = response.data.message;

      return Promise.reject(error);
    }

    return response;
  },
  error => {
    console.error(error);
    if (error.response && error.response.data && (error.response.data.detail || error.response.data.message)) {
      let errorMessage = `Server response: ${error.response.data.detail || error.response.data.message}`;
      if (errorMessage.length > 200) {
        errorMessage = errorMessage.split("");
        errorMessage.length = 200;
        errorMessage = errorMessage.join("");
      }

      error.message = errorMessage;
    }
    if (error.response && error.response.data && error.response.data.title) {
      error.title = error.response.data.title;
    }

    throw error;
  },
);

export { axiosInstance };

/**
 * Sets JWT token into the axios instance
 * @param {String} token Auth token
 * @param {String} username Name of the current user
 * @param {Object} options Options object
 * @param {Boolean} [options.remember] Should token be saved locally in the browser
 * @param {Number} [options.expires] Expiration duration (days, default set to 1/24 (an hour))
 */
export const setAuthToken = (token, username, { remember = false, expires = 1 / 24 } = {}) => {
  if (remember) {
    cookies.set("token", token, { expires });
  }
  if (username) {
    cookies.set("username", username, { expires });
  }
  axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

/**
 * Clears JWT token from the axios instance and cookies
 */
export const clearAuthToken = () => {
  cookies.remove("token");
  cookies.remove("username");
  axiosInstance.defaults.headers.Authorization = undefined;
};

/**
 * Returns current auth token in the axios instance
 * @returns {String | undefined}
 */
export const getAuthToken = () => {
  return axiosInstance.defaults.headers.Authorization;
};
