import { takeLatest, getContext, call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { showError } from "@/store/rootActions";
import {
  ADMIN_LOGIN_REQUEST,
  MONEY_CREATOR_LOGIN_REQUEST,
  adminLoginSuccess,
  adminLoginFailure,
  moneyCreatorLoginSuccess,
  moneyCreatorLoginFailure,
} from "./actions";

export function* mainSaga() {
  yield takeLatest(ADMIN_LOGIN_REQUEST, adminLoginSaga);
  yield takeLatest(MONEY_CREATOR_LOGIN_REQUEST, moneyCreatorLoginSaga);
}

function* adminLoginSaga({ payload }) {
  try {
    const api = yield getContext("api");
    const { id_token: token } = yield call(api.auth.adminLogin, payload);

    api.setAuthToken(token, payload.username, { remember: true });

    yield put(adminLoginSuccess(payload.username));
    yield put(push("/"));
  } catch (err) {
    yield put(adminLoginFailure(err));
    yield put(showError(err));
  }
}

function* moneyCreatorLoginSaga({ payload: { username, password, privateKey } }) {
  try {
    const api = yield getContext("api");
    const { id_token: token } = yield call(api.auth.moneyCreatorLogin, {
      username,
      password,
    });

    api.setAuthToken(token);

    yield put(moneyCreatorLoginSuccess({ accountId: `${username}@nbc`, privateKey }));
    yield put(push("/"));
  } catch (err) {
    yield put(moneyCreatorLoginFailure(err));
    yield put(showError(err));
  }
}
