import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import { i18n } from "element-react/next";
import en from "element-react/src/locale/lang/en";
import { store, history } from "./store";
import Login from "./containers/Login";
import { App } from "./containers/App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import styles from "./styles.module.scss";
import "element-theme-default";
import { routes } from "./routesDict";

i18n.use(en);

export function Root() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <div className={styles.root}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path={routes.login} component={Login} />
              <Route component={App} />
            </Switch>
          </ConnectedRouter>
        </div>
      </Provider>
    </ErrorBoundary>
  );
}
