import { createBaseType } from "@/services/helpers/actions";

const { createType, createAction } = createBaseType("login");

export const ADMIN_LOGIN_REQUEST = createType("ADMIN_LOGIN_REQUEST");
export const ADMIN_LOGIN_SUCCESS = createType("ADMIN_LOGIN_SUCCESS");
export const ADMIN_LOGIN_FAILURE = createType("ADMIN_LOGIN_FAILURE");

export const MONEY_CREATOR_LOGIN_REQUEST = createType(
  "MONEY_CREATOR_LOGIN_REQUEST",
);
export const MONEY_CREATOR_LOGIN_SUCCESS = createType(
  "MONEY_CREATOR_LOGIN_SUCCESS",
);
export const MONEY_CREATOR_LOGIN_FAILURE = createType(
  "MONEY_CREATOR_LOGIN_FAILURE",
);

export const adminLoginRequest = createAction(ADMIN_LOGIN_REQUEST);
export const adminLoginSuccess = createAction(ADMIN_LOGIN_SUCCESS);
export const adminLoginFailure = createAction(ADMIN_LOGIN_FAILURE);

export const moneyCreatorLoginRequest = createAction(
  MONEY_CREATOR_LOGIN_REQUEST,
);
export const moneyCreatorLoginSuccess = createAction(
  MONEY_CREATOR_LOGIN_SUCCESS,
);
export const moneyCreatorLoginFailure = createAction(
  MONEY_CREATOR_LOGIN_FAILURE,
);
