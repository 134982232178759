import React from "react";
import { Spinner } from "../Spinner";
import styles from "./styles.module.scss";

export function LoadingBackground() {
  return (
    <div className={styles.background}>
      <Spinner />
    </div>
  );
}
