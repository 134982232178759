import { axiosInstance as axios } from "../instance";

async function getRoles() {
  const { data } = await axios.get("/tvs-v1/api/systemUsers/roles");

  return data;
}

async function getSystemUsers(bankId) {
  const { data } = await axios.get(`/tvs-v1/api/systemUsers?bankId=${bankId}`);

  return data;
}

async function createSystemUser(bankId, payload) {
  const { data } = await axios.post(
    `/tvs-v1/api/systemUsers?bankId=${bankId}`,
    payload,
  );

  return data;
}

async function updateSystemUser(payload) {
  const { data } = await axios.put("/tvs-v1/api/systemUsers", payload);

  return data;
}

async function changeSystemUserPassword(payload) {
  const { data } = await axios.put(
    "/tvs-v1/api/systemUsers/changePassword",
    payload,
  );

  return data;
}

async function syncToFastClient(bankId) {
  const { data } = await axios.post(
    `/tvs-v1/api/systemUsers/synchronize?bankId=${bankId}`,
  );

  return data;
}

export {
  getRoles,
  getSystemUsers,
  createSystemUser,
  updateSystemUser,
  changeSystemUserPassword,
  syncToFastClient,
};
