import { axiosInstance as axios } from "../instance";

async function getLimits() {
  const { data } = await axios.get("/tvs-v1/api/kyc-limits");

  return data;
}

async function updateLimit(payload) {
  const { data } = await axios.put("/tvs-v1/api/kyc-limits", payload);

  return data;
}

async function createLimit(payload) {
  const { data } = await axios.post("/tvs-v1/api/kyc-limits", payload);

  return data;
}

async function setDefault(payload) {
  const { data } = await axios.put(
    "/tvs-v1/api/kyc-limits/set-default",
    payload,
  );

  return data;
}

export { getLimits, createLimit, updateLimit, setDefault };
