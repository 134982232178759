import { createBaseType } from "@/services/helpers/actions";

const { createType, createAction } = createBaseType("root");

export const SHOW_ERROR = createType("SHOW_ERROR");
export const SHOW_NOTIFICATION = createType("SHOW_NOTIFICATION");
export const CHANGE_QUERY_PARAMS = createType("CHANGE_QUERY_PARAMS");

export const showError = createAction(SHOW_ERROR);
export const showNotification = createAction(SHOW_NOTIFICATION);
export const changeQueryParams = createAction(CHANGE_QUERY_PARAMS);
