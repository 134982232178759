import React, { useRef } from "react";
import { Form, Input, Button } from "element-react/next";
import { func, bool } from "prop-types";
import Helmet from "react-helmet";
import { useFormChange } from "@/services/helpers/hooks";
import { APP_NAME } from "@/services/constants";
import { InputPassword } from "../InputPassword";
import { Container } from "../Container";
import styles from "./styles.module.scss";

const formRules = {
  username: [
    { required: true, message: "Please enter a username", trigger: "change" },
    {
      pattern: /\w/,
      message: "Field must contain only latin characters and numbers",
      trigger: "change",
    },
  ],
  password: [
    { required: true, message: "Please enter a password", trigger: "change" },
  ],
};

const initialForm = {
  username: "",
  password: "",
};

export function Login({ onAdminLogin, isLoading }) {
  const [currentForm, changeForm] = useFormChange(initialForm);
  const form = useRef(null);

  const onSubmit = event => {
    event.preventDefault();

    form.current.validate(isValid => {
      if (isValid) {
        onAdminLogin(currentForm);
      }
    });
  };

  return (
    <div className={styles.page}>
      <Helmet title={`${APP_NAME} - Login`} />
      <div className={styles.wrapper}>
        <h2 className={styles.header}>Log in</h2>
        <Container className={styles.container}>
          <Form
            ref={form}
            className={styles.form}
            model={currentForm}
            rules={formRules}
            onSubmit={onSubmit}
          >
            <Form.Item label="Username" prop="username">
              <Input
                value={currentForm.username}
                onChange={value => changeForm(value, "username")}
              />
            </Form.Item>
            <Form.Item label="Password" prop="password">
              <InputPassword
                value={currentForm.password}
                onChange={value => changeForm(value, "password")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.loginButton}
                type="primary"
                nativeType="submit"
                loading={isLoading}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Container>
      </div>
    </div>
  );
}

Login.propTypes = {
  onAdminLogin: func.isRequired,
  isLoading: bool.isRequired,
};
