const errorChecker = (name, saga) => {
  if (!name) throw new Error("You haven't provided name of component");
  if (!saga) throw new Error("You haven't provided saga");
  if (typeof name !== "string")
    throw new Error("A name of component is not a string");
  if (saga.constructor.name !== "GeneratorFunction")
    throw new Error("Saga should be a generator function");
};

/**
 * A factory that produce function to dynamically load saga to the middleware.
 * @param {Object} store A store.
 *
 * @returns {function} Returns a function that accepts your injecting saga.
 */
const createSagaInjector = store => ({ name, saga }) => {
  if (process.env.NODE_ENV === "development") {
    errorChecker(name, saga);
  }

  // TODO: add hot reloading for sagas
  if (!Reflect.has(store.injectedSagas, name)) {
    store.runSaga(saga);
  }
};

export { createSagaInjector };
