import { axiosInstance as axios } from "../instance";

/**
 * Auth method for admin
 * @param {Object} payload
 * @param {String} payload.username - Username
 * @param {String} payload.password - Password
 * @returns {*}
 */
async function adminLogin(payload) {
  const { data } = await axios.post("/api/authenticate", payload);

  return data;
}

/**
 * Auth method for money-creator
 * @param {Object} payload
 * @param {String} payload.username - Username
 * @param {String} payload.password - Password
 * @returns {*}
 */
async function moneyCreatorLogin(payload) {
  const { data } = await axios.post("/api/money-creator/authenticate", payload);

  return data;
}

/**
 * Changes password
 * @param {Object} payload
 * @param {String} payload.currentPassword - Current user's password
 * @param {String} payload.newPassword - New password to set (must has [8,16] symbols, 1 upper and lower letter, 1 digit and 1 special symbol)
 * @return {*}
 */
async function changePassword(payload) {
  const { data } = await axios.post("/api/users/change-password", payload);

  return data;
}

export { adminLogin, moneyCreatorLogin, changePassword };
