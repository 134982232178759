import { reducerInjector } from "@/store";

/**
 * High order component that injects your reducer.
 * @param {string} name general name of component and reducer.
 * @param {function} reducer a reducer that should be injected.
 *
 * @returns {function} Returns a function that accepts your component.
 */
export function withReducer(name, reducer) {
  reducerInjector({ name, reducer });

  return component => component;
}
