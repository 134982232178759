import React, { Component } from "react";
import { node } from "prop-types";
import { Container } from "../Container";
import styles from "./styles.module.scss";

export class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // eslint-disable-next-line no-console
    console.error("An error occurred during rendering:", error);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className={styles.wrapper}>
          <Container className={styles.container}>
            <h2 className={styles.header}>
              <i className={`el-icon-warning ${styles.warningIcon}`} />
              An error occurred
            </h2>
            <div>Sorry, but something went wrong.</div>
            <div>This app is crashed and error is logged in the console.</div>
          </Container>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
};
