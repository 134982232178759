import { LOCATION_CHANGE, push } from "connected-react-router";
import { takeLatest, takeEvery, getContext, select, call, put } from "redux-saga/effects";
import { Notification, Message } from "element-react/next";
import { pathnameSelector } from "@/services/helpers/selectors";
import queryString from "query-string";
import { SHOW_ERROR, SHOW_NOTIFICATION, changeQueryParams } from "./rootActions";

export function* rootSaga() {
  yield takeLatest(LOCATION_CHANGE, locationChangeSaga);
  yield takeEvery(SHOW_ERROR, handleErrorSaga);
  yield takeEvery(SHOW_NOTIFICATION, showNotificationSaga);
}

/**
 * Saga that responds to '@@router/LOCATION_CHANGE' action
 */
function* locationChangeSaga({ payload }) {
  const api = yield getContext("api");
  const path = yield select(pathnameSelector);
  const token = yield call(api.getAuthToken);
  const queryParams = queryString.parse(payload.location.search);

  yield put(changeQueryParams(queryParams));

  if (!token && path !== "/login") {
    yield put(push("/login"));
  }
}

/**
 * Saga that responds to '@@root/SHOW_ERROR' action
 * @param {Object} action
 * @param {String} action.type
 * @param {Object} action.payload
 * @param {String} [action.payload.message] Message text that you want to display
 */
function* handleErrorSaga({ payload = { message: "Unknown error" } }) {
  console.error(payload);
  yield call(Notification.error, {
    title: payload.title || "Error",
    message: payload.message,
    duration: 10000,
  });
}

/**
 * Saga that responds to '@@root/SHOW_NOTIFICATION' action
 * @param {Object} action
 * @param {String} action.type
 * @param {Object} action.payload
 * @param {String} action.payload.message Message text that you want to display
 * @param {'success'|'warning'|'info'|'error'} action.payload.type Type of notification
 * @param {Number} [action.payload.duration] Display duration, millisecond. If set to 0, it will not turn off automatically
 */
function* showNotificationSaga({ payload: { message, type, duration = 3000 } = {} }) {
  yield call(Message, {
    showClose: true,
    message,
    type,
    duration,
  });
}
