/* eslint-disable consistent-return */
import produce from "immer";
import { useState } from "react";

/**
 * React hook to help work with form fields.
 * @param {object} initialForm object with fields of initial form.
 *
 * @returns {Array} Returns an array with current form values (state) and function to change form values (setState).
 */
export function useFormChange(initialForm) {
  const [currentForm, changeForm] = useState(initialForm);

  return [
    currentForm,
    (value, field) =>
      changeForm(
        produce(draftForm => {
          if (typeof field === "string") {
            draftForm[field] = value;
          } else {
            return value;
          }
        }, currentForm),
      ),
  ];
}
