export const routes = {
  login: "/login",
  banks: "/banks",
  systemUsers: "/system_users/:bankId",
  // limits: "/limits",
  registerMoneyCreator: "/register-money-creator",
  issueMoney: "/issue-money",
  transferMoney: "/transfer-money",
  keyRecoveryRequests: "/key_recovery_requests",
};
