/**
 * A little helper that creates actions and type constants.
 * @param {string} baseName General name for your types of redux duck.
 *
 * @returns {Object} Returns an object containing two additional helper functions to create contants and actions.
 */
export function createBaseType(baseName) {
  function createType(type) {
    return `@@${baseName}/${type}`;
  }

  function createAction(type) {
    return function actionCreator(payload) {
      const action = {
        type,
      };

      // filter event object as payload
      if (payload && !payload.nativeEvent) action.payload = payload;

      return action;
    };
  }

  return {
    createType,
    createAction,
  };
}
