import React, { useState } from "react";
import { Input, Button, Tooltip } from "element-react/next";
import Svg from "react-inlinesvg";
import eyeOpenIcon from "@/assets/eye-open.svg";
import eyeClosedIcon from "@/assets/eye-closed.svg";
import styles from "./styles.module.scss";

export function InputPassword(props) {
  const [isPasswordVisible, changePasswordVisibility] = useState(false);

  return (
    <Input
      {...props}
      type={isPasswordVisible ? "text" : "password"}
      append={
        <Tooltip effect="dark" content={`Click to ${isPasswordVisible ? "hide" : "see"} password`} placement="bottom">
          <Button className={styles.passwordButton} onClick={() => changePasswordVisibility(!isPasswordVisible)}>
            <Svg src={isPasswordVisible ? eyeOpenIcon : eyeClosedIcon} cacheGetRequests />
          </Button>
        </Tooltip>
      }
    />
  );
}
