import { connect } from "react-redux";

/**
 * High order component that adds role prop from redux store.
 * @param {ReactNode} component Accepts react component.
 *
 * @returns {function} Returns a connected component with "role" prop.
 */
export function withRole(component) {
  return connect(state => ({ role: state.login.role }))(component);
}
