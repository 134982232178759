import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "./history";
import { queryParamsReducer } from "./queryParamsReducer";

export function createRootReducer(injectedReducers = {}) {
  return combineReducers({
    router: connectRouter(history),
    queryParams: queryParamsReducer,
    ...injectedReducers,
  });
}
